import React from 'react';
import logo from '../images/logo-1.png';

function ComingSoon(){
    return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
            <h1>Website Coming Soon!</h1>
            <img src={logo} style={{height:'200px', width:'150px'}}/>
        </div>
    )
}

export default ComingSoon;