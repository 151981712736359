export const marutiAmarkunj = [
    {url: require('./Maruti Amarkunj/1.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/2.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/3.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/4.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/5.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/6.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/7.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/8.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/9.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/10.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/11.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/12.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/13.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/14.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/15.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/16.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/17.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/18.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/19.jpg'), type: "image"},
];

export const sahjanandHeliconia= [
    {url: require('./Sahjanand Heliconia/1.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/2.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/3.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/4.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/5.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/6.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/7.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/8.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/9.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/10.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/11.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/12.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/13.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/14.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/15.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/16.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/17.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/18.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/19.jpg'), type: "image"},
]